import { Bio } from '../data/constants';
import '../styles/components/footer.css';

export function Footer() {
    return (
        <footer className='footer-container'>
            <div className='footer-content'>
                <div className='footer-bio'>
                <p>© 2024 Gijs Soeteman. All rights reserved.</p>
                </div>
                <div className='footer-links'>
                    <a href={Bio.github} target='_blank' rel='noreferrer'>
                        <img src='images/github-icon.jpg' alt='GitHub' />
                    </a>
                    <a href={Bio.itch} target='_blank' rel='noreferrer'>
                        <img src='images/itch-icon.png' alt='Itch' />
                    </a>
                    <a href={Bio.linkedin} target='_blank' rel='noreferrer'>
                        <img src='images/linkedin-icon.jpg' alt='LinkedIn' />
                    </a>
                    <a href={Bio.reddit} target='_blank' rel='noreferrer'>
                        <img src='images/reddit-icon.jpg' alt='Reddit' />
                    </a>
                    <a href={Bio.x} target='_blank' rel='noreferrer'>
                        <img src='images/x-icon.jpg' alt='X' />
                    </a>
                </div>
            </div>
        </footer>
    );
}
