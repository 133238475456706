import React from 'react';
import '../styles/project-components/projects.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function SpaceTrashers() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
    };

    return (
        <div className='wrapper'>
            <section id='spacetrashers'>
                <div className='header'>
                    <div className='logo'>
                        <img src="../images/space-trashers/logo_glow.png" alt="space trashers" />
                    </div>
                </div>
            </section>
            <section id='spacetrashers-intro'>
                <div className="intro spacetrashers">
                    <div className="intro-content">
                        {/* TODO change this */}
                        <iframe className='demo' width="640" height="360" src="https://www.youtube.com/embed/iQYHB_waGko?si=QsqIazu8lU7EfRck" title="YouTube video player"
                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <ul>
                            <li><span className='bold-text'>Team Composition:</span> Solo Developer</li>
                            <li><span className='bold-text'>Timeframe:</span> 8 Weeks</li>
                            <li><span className='bold-text'>Roles:</span> Game Designer, Developer, Artist</li>
                            <li><span className='bold-text'>Released:</span> 2023</li>
                            <li><span className='bold-text'>Platform:</span> PC (Browser)</li>
                            <li><span className='bold-text'>Technologies & tools:</span> HTML, CSS, P5.JS, Paint.NET</li>
                        </ul>
                    </div>

                    <div className="description">
                        <p>
                            Space Trashers is the first game I developed. It is a simple, yet fresh new spin on the classic arcade shooter genre.
                            The game features a shooting and non-shooting enemy and a final boss fight. As the player progresses through the game, they can purchase power-ups from the shop to help them in their journey.
                        </p>
                        <iframe className='itch-widget' frameBorder="0" src="https://itch.io/embed/3111740?bg_color=020407&amp;fg_color=ffffff&amp;border_width=0&amp;link_color=793a80"
                            width="501" height="165"><a href="https://pprmsk.itch.io/space-trashers">Space Trashers by papermask</a></iframe>
                    </div>
                </div>
            </section>
            <section id="spacetrashers-involvement">
                <div className="involvement">
                    <h1>My Involvement</h1>
                    <p className="subtitle">These are the most important aspects I worked on.</p>
                    <div className="card-wrapper">
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Game Design"
                                descriptions={[
                                    'Designed setting, enemies, bosses and their meaning within the game',
                                    'Made decisions on game mechanics, such as power-ups and weapons',
                                    'Balanced difficulty and progression based on user feedback',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Development"
                                descriptions={[
                                    'Implemented player movement and shooting mechanics',
                                    'Inserted a simple database to store high scores',
                                    'Created enemy movement patterns and simplistic boss AI',
                                    'Created a simple wave system for enemy spawning',
                                    'Implemented a simple randomized shooting pattern for enemies',
                                    'Designed and created a shop system for the player to purchase power-ups',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Art"
                                descriptions={[
                                    'Designed and created all sprites for the game (except for backgrounds)',
                                    'Made animations for the player and the bullets',
                                    'Created simplistic UI elements using HTML and CSS',
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id='spacetrashers-design'>
                <div className='project-design spacetrashers-design'>
                    <h1>Project Design</h1>
                </div>
            </section>
            <section id='spacetrashers-images'>
                <div>
                    <h1>Art Reel</h1>
                </div>
                <div className='images'>
                    <Slider {...settings}>
                        {/* todo change these */}
                        <ProjectImage src='images/space-trashers/player.gif' alt='player' />
                        <ProjectImage src='images/space-trashers/spaceshipBossLeft.gif' alt='boss' />
                        <ProjectImage src='images/space-trashers/enemyAggressive.png' alt='enemyAggressive' />
                        <ProjectImage src='images/space-trashers/enemyPassive.png' alt='enemyPassive' />
                        <ProjectImage src='images/space-trashers/bullet.gif' alt='bullet' />
                        <ProjectImage src='images/space-trashers/shopInterface.png' alt='shop' />
                        <ProjectImage src='images/space-trashers/tutorial.png' alt='tutorial' />
                    </Slider>
                </div>
            </section>
        </div>
    );
};

function ProjectImage(props: { src: string, alt: string }) {

    return (
        <a className="image-card">
            <img src={props.src} alt={props.alt} />
        </a>
    );
}

export function ProjectInvolvementCard(props: { title: string, descriptions: string[] }) {
    return (
        <div className="project-inv-card">
            <h2>{props.title}</h2>
            <ul>
                {props.descriptions.map((desc, index) => (
                    <li key={index}>{desc}</li>
                ))}
            </ul>
        </div>
    );
}

export default SpaceTrashers;