/**
 * @file index.tsx
 * @description This file contains the main entry point for the web application.
 * It renders the Navigator and Pages components, which are used to navigate between different pages in the application.
 * @author Gijs Soeteman
 */

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// import { Test } from './components/Test';
import { Pages } from './util/pages';
import { Home } from './pages/home';
import { Footer } from './components/Footer';
import { Navigator } from './components/Navigator';
import './styles/styles.css';

// Select the root element
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Render the web app
root.render(
    <BrowserRouter>
        <Navigator />
        <div className='page-container'>
            <Pages />
        </div>
        <Footer />
    </BrowserRouter>
);