import React from 'react';
import '../styles/project-components/projects.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function WorldEater() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
    };

    return (
        <div className='wrapper'>
            <section id='world-eater-ascent'>
                <div className='header'>
                    <div className='logo'>
                        <img src="../images/world-eater/logo_glow.png" alt="world eater" />
                    </div>
                </div>
            </section>
            <section id='world-eater-intro'>
                <div className="intro worldeater">
                    <div className="intro-content">
                        <iframe className='demo' width="640" height="360" src="https://www.youtube.com/embed/U_FCgnhmWtU?si=FTYU37MIN-a7djo8" title="YouTube video player"
                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <ul>
                            <li><span className="bold-text">Team Composition:</span> 4 People (Demo Solo Developer)</li>
                            <li><span className="bold-text">Timeframe:</span> 4 Months (Demo 1 Week)</li>
                            <li><span className="bold-text">Roles:</span> Game Designer, Developer</li>
                            <li><span className="bold-text">Release date:</span> TBA</li>
                            <li><span className="bold-text">Platform:</span> PC (WebGL)</li>
                            <li><span className="bold-text">Technologies & tools:</span> Unity, .NET, Blender, Mixamo, Paint.NET</li>
                        </ul>
                    </div>

                    <div className="description">
                        <p>
                            WORLD EATER is a 3D action game where you play as an astronaut who is tasked with defeating the World Eater.
                            The game is set in space, where you have to fight on multiple planetary surfaces. Run around planets to dodge attacks and seize your next chance to deal damage.
                            With the help of your jetpack and hammer, nothing can stop you!
                        </p>
                        <iframe className='itch-widget' frameBorder="0" src="https://itch.io/embed/3053952?border_width=0&amp;bg_color=000000&amp;fg_color=ffffff&amp;link_color=03214a&amp;border_color=bebebe"
                            width="501" height="165"><a href="https://sanderkofficial.itch.io/world-eater">World Eater by Sander, LennonStolk, papermask, Chrisisbezig</a></iframe>
                    </div>
                </div>
            </section>
            <section id="world-eater-involvement">
                <div className="involvement">
                    <h1>My Involvement</h1>
                    <p className="subtitle">These are the most important aspects I worked or am working on.</p>
                    <div className="card-wrapper">
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Game Design"
                                descriptions={[
                                    'Wrote the base game design document and its intricacies',
                                    'Designed the bossfight, including phases and attacks',
                                    'Designed the world and the player character',
                                    'Wrote the backstory and designed the setting',
                                    'Currently working on expanding the game design document',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Development"
                                descriptions={[
                                    'Developed an entire gravity system from scratch',
                                    'Implemented a boss state machine AI based on probability',
                                    'Designed and developed a shockwave system, which shapes objects around the planetary surface',
                                    'Made basic player controls, camera controls and movement',
                                    'Currently working on further expanding the boss state machine and its probability system',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Art"
                                descriptions={[
                                    'Designed the demo with basic shapes and colors',
                                    'Contributing to figuring out the art direction and style',
                                    'Currently working on creating logos and other publication art assets',
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id='world-eater-design'>
                <div className='project-design worldeater-design'>
                    <h1>Project Design</h1>
                </div>
            </section>
            <section id='world-eater-images'>
                <div>
                    <h1>Art Reel</h1>
                </div>
                <div className='images'>
                    <Slider {...settings}>
                        {/* todo: add */}
                        <ProjectImage src="../images/world-eater/shockwave.gif" alt="shockwave" />
                        <ProjectImage src="../images/world-eater/planet-system.png" alt="planet system" />
                    </Slider>
                </div>
            </section>
            <section id='world-eater-links'>

            </section>
        </div>
    );
};

function ProjectImage(props: { src: string, alt: string }) {
    return (
        <a className="image-card">
            <img src={props.src} alt={props.alt} />
        </a>
    );
}

export function ProjectInvolvementCard(props: { title: string, descriptions: string[] }) {
    return (
        <div className="project-inv-card">
            <h2>{props.title}</h2>
            <ul>
                {props.descriptions.map((desc, index) => (
                    <li key={index}>{desc}</li>
                ))}
            </ul>
        </div>
    );
}

export default WorldEater;