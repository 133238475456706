import { educations } from '../data/constants'; // Ensure the correct path to your data
import { useEffect, useRef } from 'react';
import '../styles/components/education.css';

export function Education() {
    // Create a ref to store multiple item refs
    const rightRefs = useRef<(HTMLDivElement | null)[]>([null, null]);

    useEffect(() => {
        const options = {
            root: null, // relative to the viewport
            rootMargin: '60px', // 50% from the bottom of the viewport
            threshold: [0.3], // trigger when 50% of the element is visible
        };

        const callback = (
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
        ) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('move-right');
                } else {
                    entry.target.classList.remove('move-right');
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        rightRefs.current.forEach(ref => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            rightRefs.current.forEach(ref => {
                if (ref) {
                    observer.unobserve(ref);
                }
            });
        };
    }, []);

    return (
        <section id='education'>
            <div className='page-wrapper'>
                <div className='education-container'>
                    <div className='education-intro'>
                        <h2>Education</h2>
                        <p className='education-desc subtitle'>
                            The institutions where I've studied and the degrees
                            I've earned.
                        </p>
                    </div>
                    <div className='education-panel'>
                        {educations.map((education, index) => (
                            <div className='education-item-wrapper'>
                                <div key={index} className='education-item'>
                                    {/* Content (Image, Degree, School, Date) */}
                                    <div className='education-item-header'>
                                        <img
                                            src={education.img}
                                            alt={`${education.school} logo`}
                                        />
                                        <h3>{education.degree}</h3>
                                    </div>
                                    <div className='education-item-body'>
                                        <h4>{education.school}</h4>
                                        <p>{education.date}</p>
                                    </div>
                                </div>
                                <div
                                    className='item-desc-card'
                                    ref={el => (rightRefs.current[index] = el)}
                                >
                                    <div className='education-item-desc'>
                                        <p>{education.desc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
