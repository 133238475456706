export interface BioType {
    name: string[];
    location: string;
    description: string;
    github: string;
    linkedin: string;
    x: string;
    reddit: string;
    itch: string;
    image: string;
    mail: string;
}

export const Bio: BioType = {
    name: ['Gijs Soeteman','"PAPERMASK"'],
    location: 'The Netherlands',
    description:
        "I'm currently studying HBO-ICT, specializing in Game Development at the Amsterdam University of Applied Sciences. In the meantime I'm working on my own games and projects.",
    github: 'https://github.com/pprmsk',
    linkedin: 'https://www.linkedin.com/in/gijs-soeteman-9292561a1/',
    x: 'https://www.x.com/papermsk',
    reddit: 'https://www.reddit.com/user/pprmsk',
    itch: 'https://pprmsk.itch.io/',
    image: 'images/me/selfie1.png',
    mail: 'mailto: gijssoeteman@gmail.com',
};

export interface EducationInfo {
    img: string;
    school: string;
    date: string;
    desc: string;
    degree: string;
}

export const educations: EducationInfo[] = [
    {
        img: 'images/hva-logo.png',
        school: 'Hogeschool van Amsterdam, Amsterdam',
        date: 'Sep 2023 - Present',
        desc: 'I am currently studying for a Bachelor’s degree in HBO ICT at Hogeschool van Amsterdam, specializing in Game Development. I acquired my propedeuse cum laude in the first year.',
        degree: 'Bachelor / HBO ICT',
    },
    {
        img: 'images/gl-logo.png',
        school: 'Goois Lyceum, Bussum',
        date: 'Aug 2015 - Jun 2019',
        desc: 'I finished my HAVO at Goois Lyceum in Bussum. This education helped lay a foundation for what was to come!',
        degree: 'HAVO / Economics & Society',
    },
];
