import '../styles/components/contact.css';

export function Contact() {
    return (
        <section id='contact'>
            <div className='page-wrapper'>
                <form action="https://api.web3forms.com/submit" method="POST" className="contact-form">
                    <h2>Contact Me</h2>
                    <p className="subtitle">Get in touch for inquiries, ideas, or just to say hi!</p>

                    <input type="hidden" name="access_key" value="f6b02fb1-342d-403a-86f6-ce3d68e8a470" />

                    <input type="text" id="name" name="name" placeholder='Your Name' required />

                    <input type="email" id="email" name="email" placeholder="Your Email" required />

                    <input type="text" id="subject" name="subject" placeholder="Subject" required />

                    <textarea id="message" name="message" placeholder='Message' rows={3} required></textarea>

                    <input type="checkbox" name="botcheck" className="hidden" style={{ display: 'none' }} />

                    <button type="submit">Send Message</button>
                </form>
            </div>
        </section>
    );
}
