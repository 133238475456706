import React, { useRef } from "react";
import '../styles/components/projects.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function Projects() {

	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
	};

	return (
		<section id="projects">
			<div className="page-wrapper">
				<div className="projects-container">
					<div className="projects-intro">
						<h2>My Projects</h2>
						<p className="projects-desc subtitle">
							These are some of the projects I've worked on.
						</p>
					</div>

					<div className="project-panel">

						<div className="project-panel-list-wrapper">
							<Slider {...settings}>
								<ProjectCard src='images/horimono/horimono-panel.png' alt='HORIMONO' link='horimono' />
								<ProjectCard src='images/sinful-ascent/sinful-panel.png' alt='Sinful Ascent' link='sinful-ascent' />
								<ProjectCard src='images/flappy-clone/flappy-panel.png' alt='Flappy Clone' link='flappy-clone' />
								<ProjectCard src='images/space-trashers/space-panel.png' alt='Space Invaders' link='space-trashers' />
								<ProjectCard src='images/world-eater/world-eater-panel.png' alt='World Eater' link='world-eater' />
							</Slider>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

function ProjectCard(props: { src: string, alt: string, link: string }) {

	return (
		<a href={props.link} className="project-item">
			<img src={props.src} alt={props.alt}/>
		</a>
	);
}