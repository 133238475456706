import '../styles/components/navigator.css';

export function Navigator() {
    return (
        <nav className='nav-element'>
            <ul>
                <NavigatorElement title='About' targetSection='/#about' />
                <NavigatorElement title='Projects' targetSection='/#projects' />
                <NavigatorElement title='Education' targetSection='/#education' />
                <NavigatorElement title='Contact' targetSection='/#contact' />
            </ul>
        </nav>
    );
}

function NavigatorElement({ toggleMenu, title, targetSection }: { toggleMenu?: () => void; title: string; targetSection: string }) {
    return (
        <a href={targetSection} className='nav-item' onClick={toggleMenu}>
            {title}
        </a>
    );
}