import React from 'react';
import '../styles/project-components/projects.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function FlappyClone() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
    };

    return (
        <div className='wrapper'>
            <section id='flappy-clone'>
                <div className='header'>
                    <div className='logo'>
                        <img src="../images/flappy-clone/logo_glow.png" alt="Flappy Clone" />
                    </div>
                </div>
            </section>
            <section id='flappy-intro'>
                <div className="intro flappy">
                    <div className="intro-content">
                        {/* replace this!! */}
                        <iframe className='demo' width="640" height="360" src="https://www.youtube.com/embed/T-L6skMPApI?si=yQXzL7miNmhGYI12" title="YouTube video player"
                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <ul>
                            <li><span className='bold-text'>Team Composition:</span> Solo Developer</li>
                            <li><span className='bold-text'>Timeframe:</span> 1 Week</li>
                            <li><span className='bold-text'>Roles:</span> Game Designer, Developer, Art</li>
                            <li><span className='bold-text'>Released:</span> 2024</li>
                            <li><span className='bold-text'>Platform:</span> PC (WebGL)</li>
                            <li><span className='bold-text'>Technologies & tools:</span> Unity Engine, .NET, Paint.NET</li>
                        </ul>
                    </div>

                    <div className="description">
                        <p>
                            (NOT) Your Average Flappy Clone is, as the title suggests, not your average Flappy Bird clone.
                            We were tasked with creating a Flappy Bird game, to get a better understanding of using Unity.
                            I was up for a challenge, so I decided to add an interesting twist.
                            The game features a 2D to 3D perspective shift mechanic after a certain amount of points,
                            which adds not only a surprise, but also a new challenge for the player to overcome.
                        </p>
                        <iframe className='itch-widget' frameBorder="0" src="https://itch.io/embed/2965758?bg_color=020407&amp;fg_color=ffffff&amp;link_color=aa59f3&amp;border_color=343639"
                            width="501" height="165"><a href="https://pprmsk.itch.io/not-your-average-flappy-clone">(NOT) Your Average Flappy Clone by papermask</a></iframe>
                    </div>
                </div>
            </section>
            <section id="flappy-involvement">
                <div className="involvement">
                    <h1>My Involvement</h1>
                    <p className="subtitle">These are the most important aspects I worked on.</p>
                    <div className="card-wrapper">
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Game Design"
                                descriptions={[
                                    'Built upon the original assignment to create a unique experience',
                                    'Designed the perspective shift mechanic',
                                    'Created balanced difficulty scaling based on user feedback.',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Development"
                                descriptions={[
                                    'Created a 2D to 3D perspective shift mechanic',
                                    'Implemented Flappy Bird mechanics using the Unity Input System',
                                    'Wrote custom scripts to scroll textures for the effect of movement',
                                    'Created custom scripts for spawning and moving buildings',
                                    'Developed custom water particle systems for the manhole fountains',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Art"
                                descriptions={[
                                    'Planned out the art style and art direction',
                                    'Created prefabs for custom obstacle sections and background elements',
                                    'Designed layout and positioning of assets',
                                    'Sourced and customised assets for the game',
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id='flappy-design'>
                <div className='project-design flappy-design'>
                    <h1>Project Design</h1>

                </div>
            </section>
            <section id='flappy-images'>
                <div>
                    <h1>Art Reel</h1>
                </div>
                <div className='images'>
                    <Slider {...settings}>
                        <ProjectImage src='images/flappy-clone/screenshot.png' alt='screenshot' />
                        <ProjectImage src='images/flappy-clone/screenshot1.png' alt='screenshot1' />
                    </Slider>
                </div>
            </section>
        </div>
    );
};

function ProjectImage(props: { src: string, alt: string }) {

    return (
        <a className="image-card">
            <img src={props.src} alt={props.alt} />
        </a>
    );
}

export function ProjectInvolvementCard(props: { title: string, descriptions: string[] }) {
    return (
        <div className="project-inv-card">
            <h2>{props.title}</h2>
            <ul>
                {props.descriptions.map((desc, index) => (
                    <li key={index}>{desc}</li>
                ))}
            </ul>
        </div>
    );
}

export default FlappyClone;