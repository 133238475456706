import { About } from '../components/About';
import { Contact } from '../components/Contact';
import { Education } from '../components/Education';
import { Projects } from '../components/Projects';

export function Home() {
    return (
        <div className='page-content'>
            <About />
            <Projects />
            <Education />
            <Contact />
        </div>
    );
}

export default Home;