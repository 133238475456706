import { Route, Routes } from 'react-router-dom';
import Horimono from '../pages/horimono';
import SinfulAscent from '../pages/sinful-ascent';
import SpaceTrashers from '../pages/space-trashers';
import WorldEater from '../pages/world-eater';
import FlappyClone from '../pages/flappy-clone';
import Home from '../pages/home';

export function Pages() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/horimono' element={<Horimono />} />
            <Route path='/sinful-ascent' element={<SinfulAscent />} />
            <Route path='/space-trashers' element={<SpaceTrashers />} />
            <Route path='/world-eater' element={<WorldEater />} />
            <Route path='/flappy-clone' element={<FlappyClone />} />
        </Routes>
    );
}